.nuoVirsaus {
  padding: 80px 0px 0px 0px;
}
/* users */
.bandymas {
  /* padding: 500px; */
  background-color: #8a8e95;
  min-height: 50vh;
  display: flex;
  flex-direction: row;
}

/* header */
.header {
  position: fixed;
  width: 100%;
  background-color: lightslategrey;
  min-height: 10vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  z-index: 100;
}

/* Menu  */
.menu {
  margin-left: 50px;
  display: flex;
  align-items: stretch;
  /* align-content: space-between; */
  color: rgb(0, 0, 0);
}
.menuItem {
  margin-right: 10px;
  font-weight: 300;
  color: black;
}
.active {
  margin-right: 10px;
  color: red;
  font-weight: 800;
}
/* shoping card element */
.schopBtn {
  font-size: 24px;
}
.icon {
  padding-left: 25px;
  /* background: url("C:\web\Redux_Tolkit_bandymai\bandymas\src\icons\noun-shop-1731429.png") no-repeat left; */
  background: url(../icons/noun-shop-1731429.png) no-repeat left;
  background-size: 30px;
}
.shopingIconElmn {
  margin-left: auto;
}

.gaidysClass {
  color: black;
}
.gaidysErrorClass {
  color: red;
}
.gaidysLoadingClass {
  color: darkgray;
}

/* home page, item */

.pageContainer {
  margin: 10px 50px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: baseline;
  align-content: center;
}
.itemCard_container {
  margin: 0.3% 0.3%;
  padding: 1% 1%;
  border-style: solid;
  border-width: 1px;
  border-radius: 5%;
  background-color: antiquewhite;
  display: block;
  height: 400px;
  width: 350px;
  overflow: hidden;
  position: relative;
}

.itemContainer {
  /* border: 1px solid red; */
  width: 100%;
  height: 60%;
  display: flex;
}
.itemContainerPrice {
  color: rgb(86, 216, 35);
  /* font-size: large; */
  font-weight: 900;
}
.imgContainer {
  /* align-items: start; */
  /* border: 1px solid red; */
  width: 60%;
  height: 100%;
}
.itemCard_container img {
  /* object-fit: contain; */
  /* or */

  width: 100%;
  height: 100%;
  object-fit: cover;
  overflow: hidden;
  /* border-radius: 10%; */
  /* background-color: antiquewhite; */
  /* border-style:solid;
    border-width: 1px; */
}
.itemPrice {
  padding: 0 0 0 5px;
}
.itemPrice h4 {
  margin: 0;
}
.itemDescriptionBox {
  /* border: 1px solid red; */
  /* overflow: hidden;
  position: relative; */
  padding: 0;
  margin: 0;
}
.itemDescriptionBox p {
  margin-top: 0;
  width: 100%;
  height: 45px;
  /* border: 1px solid #000000; */
  display: inline;
  -webkit-line-clamp: 2;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  word-wrap: break-word; /* Important for long words! */
}
.itemContainerQtyContainer {
  display: flex;
}
.itemContainerQtyValue {
  width: 15px;
  color: red;
  /* font-size:large ; */
}

/* Shop Card */
.shopCardTableButton > button {
  background-color: gainsboro;
  border: solid 1px;
  border-radius: 100%;
}
.shopCardTableButtons {
  display: flex;
  flex-direction: column;
}

/* todos ************** */
.neperbraukta {
  color: red;
}
.perbraukta {
  text-decoration-line: line-through;
  color: green;
}
.zinute {
  color: green;
}
